import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useStartPlanning from 'content-queries/mx/new/start-planning'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { BasicContent, ContentColumn, MainContent } from 'mx/components/new'
import Layout from 'mx/layout'

const StartPlanningPage = ({ path }: { path: string }) => {
  const { data } = useStartPlanning()
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Start Planning',
    })
  }, [])

  const [
    {
      node: {
        sectionModules: [hero, technology, , compare],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <MainContent header data={hero} />
      <BasicContent data={technology} classname={tw`mt-20 lg:mt-32`} />
      <ContentColumn data={compare} classname={tw`my-20 lg:my-32`} />
    </Layout>
  )
}

export default StartPlanningPage

export const Head = () => {
  const { data } = useStartPlanning()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/start-planning"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
